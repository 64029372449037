// 404 page
@import '../../styles/component.scss';

.page {
  composes: d-flex flex-column justify-content-center align-center text-center from global;

  height: 100vh;
}

.helpfulLinksHeaderTextWrap {
  margin-bottom: 1rem;
}

.helpfulLinksHeaderText {
  composes: h5 mb-2 from global;
}

.helpfulLinks {
  composes: list-unstyled from global;
}
